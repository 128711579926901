import { getCurrentStage, getBaseUrl, ApiError } from '../common/config/ApiConstants';

/**
 * Interface defining the properties for an API call.
 * @interface ApiCallProps
 * @property {string} endpoint - The API endpoint to call.
 * @property {'GET' | 'POST' | 'PUT' | 'PATCH'} [method='GET'] - The HTTP method to use for the API call.
 * @property {object} [body] - The request body for POST, PUT, or PATCH requests.
 * @property {Record<string, any>} [queryParams] - Query parameters to append to the URL.
 */
export interface ApiCallProps {
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH';
    body?: object;
    queryParams?: Record<string, any>;
  }
  
  /**
   * Makes an API call with the given parameters.
   * @template T - The expected type of the API response.
   * @param {ApiCallProps} options - The options for the API call.
   * @param {string} options.endpoint - The API endpoint to call.
   * @param {'GET' | 'POST' | 'PUT' | 'PATCH'} [options.method='GET'] - The HTTP method to use.
   * @param {object} [options.body] - The request body for POST, PUT, or PATCH requests.
   * @param {Record<string, any>} [options.queryParams] - Query parameters to append to the URL.
   * @returns {Promise<T>} A promise that resolves to the API response of type T.
   * @throws {ApiError} If the API call fails or returns a non-OK status.
   */
  export const apiCall = async <T>({ endpoint, method = 'GET', body, queryParams }: ApiCallProps): Promise<T> => {
    try {
      const stage = getCurrentStage();
      console.log(stage);

      const baseUrl = getBaseUrl(stage);
      
      // Construct URL with query parameters
      const url = new URL(`${baseUrl}${endpoint}`);
      if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
          url.searchParams.append(key, value);
        });
      }
  
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined,
      });
  
      if (!response.ok) {
        throw new Error('An error occurred during the API call');
      }
      return await response.json();
  
    } catch (error) {
      console.error('API call error:', error);
      if (error instanceof Response) {
        throw new ApiError(error.status, `API call failed with status ${error.status}`);
      } else if (error instanceof Error) {
        throw new ApiError(500, error.message);
      } else {
        throw new ApiError(500, 'An unknown error occurred during the API call');
      }
    }
  };
  