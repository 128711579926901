import React, { useState } from 'react';
import { Link, Select, Checkbox, Input, FlashbarProps, ButtonDropdownProps }
  from '@cloudscape-design/components';
import { AttributeMetadata, ControlAttributesMetadata, ControlMetadata } from '@amzn/chub-model-typescript-client';
import { logger } from 'src/logger';

interface TextWithLineBreaksProps {
  text: string;
}

const MAX_DISPLAY_LENGTH = 50;

/**
 * Function to generate column definitions for the control summary table
* 
* @param {ControlAttributesMetadata | null} controlAttributesData - Metadata for control attributes
* @param {boolean} isLoading - Flag indicating if data is still loading
* @param {function} openModal - Function to open a modal with given name and value
* @returns {Array} Array of column definitions
*/
export const generateColumnDefinitions = (
  controlAttributesData: ControlAttributesMetadata | null,
  isLoading: boolean,
  openModal: (name: string, value: string) => void
) => {
  if (!controlAttributesData?.attributesMetadata?.length) {
    logger.info('controlAttributesData or attributesMetadata is empty or undefined');
    return [];
  }

  return controlAttributesData.attributesMetadata.map((attribute: AttributeMetadata) => ({
    id: attribute.name ?? '',
    header: attribute.displayName ?? '',
    sortingField: attribute.name ?? '',
    width: getColumnWidth(attribute.displayName!),
    cell: (item: Record<string, any>) => {
      const value = item[attribute.name ?? ''] ?? "-";
      if (typeof value === 'string') {
        if (value.startsWith("https://")) {
          return (
            <Link href={value} external>
              {value.length > MAX_DISPLAY_LENGTH ? `${value.substring(0, MAX_DISPLAY_LENGTH)}...` : value}
            </Link>
          );
        } else if (attribute.enableModalView && value.length > 1) {
          return (
            <Link
              href="#"
              onFollow={(event) => {
                event.preventDefault();
                openModal(attribute.name ?? '', value);
              }}
            >
              {value.substring(0, 50)}...
            </Link>
          );
        }
      }
      return value === 'null' ? '' : value;
    },
    sortingComparator: (a: Record<string, any>, b: Record<string, any>) => {
      const aValue = a[attribute.name ?? ''];
      const bValue = b[attribute.name ?? ''];

      switch (attribute.dataType) {
        case 'java.lang.String':
          return (aValue ?? '').localeCompare(bValue ?? '');
        case 'java.lang.Integer':
        case 'java.lang.Long':
        case 'java.lang.Float':
        case 'java.lang.Double':
          return (Number(aValue) || 0) - (Number(bValue) || 0);
        case 'java.lang.Boolean':
          return (aValue === bValue) ? 0 : aValue ? -1 : 1;
        case 'java.util.Date':
          return new Date(aValue).getTime() - new Date(bValue).getTime(); //TODO: Improvise this according to the date formats in backend for future usecases
        default:
          return String(aValue).localeCompare(String(bValue));
      }
    },
    editConfig: attribute.isEditable && !isLoading ? {
      ariaLabel: attribute.name,
      editIconAriaLabel: `Edit ${attribute.name}`,
      errorIconAriaLabel: `${attribute.name} Error`,
      editingCell: (
        item: Record<string, any>,
        { currentValue, setValue }: { currentValue: any; setValue: (value: any) => void }
      ) => {
        if (attribute.dataType === 'java.util.List') {
          const options = [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' }
          ];

          return (
            <Select
              autoFocus={true}
              options={options}
              selectedOption={options.find(option => option.value === currentValue) || null}
              onChange={event => setValue(event.detail.selectedOption.value)}
            /> //TODO: configure in backend
          );
        } else {
          return (
            <Input
              autoFocus={true}
              value={currentValue ?? ''}
              onChange={event => setValue(event.detail.value)}
            /> //TODO: configure in backend
          );
        }
      }
    } : undefined
  }));
};

/**
 * Determines the width of a column based on its display name
 * 
 * @param {string} displayName - The display name of the column
 * @returns {number} The width of the column in pixels
 */
export const getColumnWidth = (displayName: string) => {
  const lowercaseName = displayName.toLowerCase();
  if (lowercaseName.includes('description') || lowercaseName.includes('comments')
    || lowercaseName.includes('clause') || lowercaseName.includes('reason')
    || lowercaseName.includes('status')) {
    return 500; //TODO: configure in backend
  }
  return 175; // Default width
};

/**
 * TextWithLineBreaks Component
 * Renders text with line breaks and bold patterns
 * 
 * @param {TextWithLineBreaksProps} props - The props for the component
 * @returns {JSX.Element} The rendered text with line breaks and bold patterns
 */
export const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({ text }) => {
  const boldPatterns = ['Alterations:', 'Surrender/Restoration:'];

  const renderPart = (part: string): React.ReactNode[] => {
    const result: React.ReactNode[] = [];
    let remainingText = part;

    while (remainingText.length > 0) {
      const matchIndex = boldPatterns.findIndex(pattern => remainingText.includes(pattern));
      if (matchIndex !== -1) {
        const pattern = boldPatterns[matchIndex];
        const index = remainingText.indexOf(pattern);
        if (index > 0) {
          result.push(remainingText.slice(0, index));
        }
        result.push(<strong key={result.length}>{pattern}</strong>);
        result.push(<br key={result.length} />);
        remainingText = remainingText.slice(index + pattern.length);
      } else {
        result.push(remainingText);
        break;
      }
    }
    return result;
  };

  return (
    <>
      {text.split(/(\n)/).map((part: string, i: number) => {
        if (part === '\n') {
          return <br key={i} />;
        }
        return <React.Fragment key={i}>{renderPart(part)}</React.Fragment>;
      })}
    </>
  );
};

/**
 * Creates a Flashbar message state and setter
 * 
 * @param {() => void} onDismiss - Function to call when the message is dismissed
 * @returns {[readonly FlashbarProps.MessageDefinition[], React.Dispatch<React.SetStateAction<readonly FlashbarProps.MessageDefinition[]>>]} 
 *          A tuple containing the message state and its setter
 */
export const getFlashbarMessage = () => {
  return [{
    type: "success" as FlashbarProps.Type,
    dismissible: true,
    content: "Successfully Updated",
    dismissLabel: 'Dismiss message',
    id: 'template_editor_1',
    onDismiss: () => {}
  }] as const;
};

/**
 * Creates an array of button dropdown items
 * 
 * @param {string} controlId - The ID of the control
 * @returns {ButtonDropdownProps.ItemOrGroup[]} An array of button dropdown items
 */
export const getButtonDropdownItems = (control: ControlMetadata | null): ButtonDropdownProps.ItemOrGroup[] => [
  //TODO: This should come from backend configuration
  {
    text: "Historical Data",
    id: "hd",
    disabled: false,
    external: true,
    externalIconAriaLabel: "(opens in new tab)",
    href: "https://amazon.awsapps.com/workdocs-amazon/index.html#/folder/6a0f14e4f972f23416ff566353caf730c87360d15f3fb1a0ad6b03b08896deff"
  },
  {
    text: "Finance POC Link",
    id: "poc",
    disabled: false,
    href: "https://quip-amazon.com/nDXvA8p9Y1CI/GRC-5257-Finance-POCs-Listing#temp:C:LdF3cb64ba3abb24626b213ede4d",
    external: true,
    externalIconAriaLabel: "(opens in new tab)"
  },
  {
    text: "SOP",
    id: "sop",
    disabled: false,
    href: "https://w.amazon.com/bin/view/Fixed_Asset_Accounting_Global/GRC5257",
    external: true,
    externalIconAriaLabel: "(opens in new tab)"
  },
];