/**
 * Wrapper around the Polaris `BreadcrumbGroup` that implements client-side routing using react-router.
 *
 * @link https://polaris.a2z.com/components/breadcrumb-group
 */

import { BreadcrumbGroup, BreadcrumbGroupProps } from "@cloudscape-design/components";
import { useNavigate } from "react-router";

export function Breadcrumbs(props: BreadcrumbGroupProps) {
    const navigate = useNavigate();
    return (
        <BreadcrumbGroup
            onClick={(event) => {
                // perform client side routing
                event.preventDefault();
                navigate(event.detail.href);
            }}
            {...props}
        />
    );
}