import React from 'react';
import { SpaceBetween, HelpPanel } from "@cloudscape-design/components";

/**
 * ToolsNav Component
 *
 * This component renders a HelpPanel containing information about the Controllership Hub.
 * It provides an overview of key features, user benefits, and specific GRC controls
 * implemented in the Controllership Hub.
 *
 * @component
 * @example
 * return (
 *   <ToolsNav />
 * )
 */
const ToolsNav: React.FC = () => (
    <HelpPanel header="Controllership Hub">
        <SpaceBetween size="l">
            <p>
                Leveraging best-in-practice tech capabilities on the backend, Controllership Hub provides key
                features for the users:
            </p>
            <ul>
                <li>Metrics Monitoring & Dashboards</li>
                <li>Tasks Tracking</li>
                <li>Notifications Management</li>
                <li>Human Input & Feedback Loop</li>
                <li>Approval Workflow</li>
            </ul>
            <p>
                WW Amazon Stores Finance (ASF) users now have a centralized location to review consolidated data and
                enable performance of controls with human input and approvals via workflows. Controllership tools
                related to CAPEX & Leasing, Procurement spend, and Fixed Asset management have been on-boarded to
                Controllership Hub, with additional solutions on-boarding in the near future.
            </p>
            <p>In 2024, Controllership Hub enabled finance reviews for 3 Key GRC controls:</p>
            <ul>
                <li>
                    <strong>GRC 5257</strong> Finance Quarterly Review and Approval of Asset Retirement Obligation (ARO)
                    Estimates
                </li>
                <li>
                    <strong>GRC 5254</strong> Finance review of fully depreciated assets, so called "NBV0"
                </li>
                <li>
                    <strong>GRC 5253</strong> Finance Review of Accounting Lease Commencement Dates, so called "LCE"
                </li>
            </ul>
            <p>
                Finance Controllership Hub also hosts vital Capex and Leasing dashboards to furnish WWASF users with
                consolidated financial and operational fixed asset data views. This data utilizes the Fixed Asset
                Source of Truth (FA SSOT).
            </p>
        </SpaceBetween>
    </HelpPanel>
);

export default ToolsNav;