/**
 * ReportsPage Component
 *
 * This component renders a page displaying various reports based on the selected control.
 * It uses the AppLayout component from @cloudscape-design/components for the overall structure.
 *
 * @component
 */
import React, { useState, useMemo} from 'react';
import {
    AppLayout,
    Container,
    ExpandableSection,
    ContentLayout,
    Tabs, TabsProps, Header, SpaceBetween
} from '@cloudscape-design/components';
import TopNav from '../navigation/TopNav';
import {Outlet} from "react-router-dom";
import {useUserAlias} from "src/common/hooks/useUserAlias";
import {useControls} from "src/common/ControlContext";

import { useParams } from 'react-router-dom';
import reportConfigs, { ReportPageConfig, ReportConfig } from '../../common/config/ReportsConfig';

/**
 * Renders a list of reports for a given tab.
 *
 * @param {ReportConfig[]} reports - Array of report configurations for the tab
 * @param {ReportPageConfig} pageConfig - Overall page configuration
 * @returns {JSX.Element} Rendered reports
 */
const renderReports = (reports: ReportConfig[], pageConfig: ReportPageConfig) => {
    return (
        <SpaceBetween size="l">
            {reports.map((report, index) => (
                <Container key={index}>
                    <ExpandableSection headerText={report.headerText} defaultExpanded={false}>
                        <iframe
                            title="iframe"
                            width="100%"
                            height="1000"
                            src={`https://${pageConfig.region}.quicksight.aws.amazon.com/sn/embed/share/accounts/${pageConfig.accountId}/dashboards/${report.dashboardId}?directory_alias=${pageConfig.directoryAlias}`}
                        />
                    </ExpandableSection>
                </Container>
            ))}
        </SpaceBetween>
    );
};

/**
 * Main ReportsPage component.
 *
 * @returns {JSX.Element} Rendered ReportsPage
 */
export const ReportsPage: React.FC = () => {
    const { alias } = useUserAlias();
    // Get the list of controls using a custom hook
    const {controls} = useControls();

    // Determine the report type based on the controlId
    const { controlId } = useParams<{ controlId: string }>();
    const reportType = controlId?.toUpperCase() as keyof typeof reportConfigs;

    // Find the control matching the controlId
    const control = useMemo(() => {
        return controls.find(c => c.controlId === controlId) || null;
    }, [controls, controlId]);

    const pageConfig = reportConfigs[reportType];
    const [activeTabId, setActiveTabId] = useState(pageConfig.tabs[0].id);

    // Generate tabs based on the page configuration
    const tabs: TabsProps.Tab[] = pageConfig.tabs.map(tab => ({
        label: tab.label,
        id: tab.id,
        content: renderReports(tab.reports, pageConfig)
    }));
    return (
        <>
            {/* TopNav component */}
            <div id="b">
                <div id="h" style={{position: "sticky", top: 0, zIndex: 1002}}>
                    <TopNav userAlias={alias}
                            issueLink="https://sim.amazon.com/issues/create?assignedFolder=cb133b70-fac3-42e7-86f2-575d040a086d"
                            aboutLink="https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/"/>
                </div>
                <Outlet/>
            </div>
            {/* Main content using AppLayout */}
            <AppLayout
                navigationHide={true}
                disableContentPaddings={false}
                toolsHide={true}
                maxContentWidth={Number.MAX_VALUE}
                onToolsChange={async () => {
                    console.log("tools changed")
                }}
                content={
                    <>
                        <ContentLayout
                            defaultPadding={false}
                            disableOverlap={true}
                            maxContentWidth={Number.MAX_VALUE}
                            headerVariant={"default"}
                            header={
                                <Header
                                    variant="h1"
                                    description=""
                                >
                                    {control?.controlSubTitle}
                                </Header>

                            }
                        >
                            <SpaceBetween size="l">
                                <Tabs
                                    activeTabId={activeTabId}
                                    onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                                    tabs={tabs}
                                />
                            </SpaceBetween>
                        </ContentLayout>
                    </>
                }
            />
        </>
    );
}