import React from 'react';
import { FloatingMenu } from '@amzn/finergywebcomponents';
import '@amzn/finergywebcomponents/dist/main.css';

/**
 * Placeholder for the FinergyMenu component
 * This will be replaced with the actual FloatingMenu component if it loads successfully
 */
let FinergyMenu = (_props: any) => <div />;

/**
 * ErrorBoundary Component
 *
 * This component catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI instead of the component tree that crashed.
 */
export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * Update state so the next render will show the fallback UI.
     */
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    /**
     * Log the error to the console.
     * In a production app, this could be replaced with an error logging service.
     */
    componentDidCatch(error: any, errorInfo: any) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong loading Finergy floating menu.</h1>;
        }

        return this.props.children as JSX.Element;
    }
}

/**
 * FinergyFloatingMenu Component
 *
 * This component renders the Finergy floating menu, wrapped in an ErrorBoundary.
 * It assigns the FloatingMenu component to FinergyMenu if it loads successfully.
 *
 * @returns {JSX.Element} The rendered FinergyFloatingMenu component
 */
function FinergyFloatingMenu(): JSX.Element {
    FinergyMenu = FloatingMenu as (
        props: Parameters<typeof FloatingMenu>[0],
    ) => JSX.Element;

    return (
        <ErrorBoundary>
            <FinergyMenu
                iconTopMargin={16}
                iconLeftMargin={10}
                openMenuTopMargin={55}
            />
        </ErrorBoundary>
    );
}

export default FinergyFloatingMenu;