import { useAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import Spinner from '@cloudscape-design/components/spinner';
import { Outlet } from 'react-router-dom';

/**
 * PrivateRoute Component
 *
 * This component is used to protect routes that should only be accessible
 * to authenticated users. It uses the AWS Amplify useAuthenticator hook
 * to determine the user's authentication status.
 *
 * If the user is authenticated, it renders the child routes.
 * If the user is not authenticated, it displays a loading spinner.
 *
 * @returns {JSX.Element} The rendered component
 */
export const PrivateRoute = (): JSX.Element => {
    // Use the useAuthenticator hook to get the current authentication route
    const { route } = useAuthenticator((context) => [context.route]);

    return (
        <>
            {/* Render child routes if the user is authenticated */}
            {route === 'authenticated' && <Outlet />}

            {/* Show a loading spinner if the user is not authenticated */}
            {route !== 'authenticated' && <Spinner data-testid="page-loader" />}
        </>
    );
};